import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import ArticleItem from "components/Sections/Articles/ArticleItem"
import ArticlesSidebar from "components/Sections/Articles/ArticlesSidebar"
// Models
import { IArticle } from "models/Article"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  articles: {
    nodes: IArticle[]
  }
  tags: { group: [{ slug: string; count: number }] }
}

type PageContextProps = {
  tag: string
}

const BlogIndex: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const tag = pageContext.tag.charAt(0).toUpperCase() + pageContext.tag.slice(1)
  const articles = data.articles.nodes
  const tags = data.tags.group

  const seo: IOptionalMetaProps = {
    title: tag,
    description: `Lista de todos los artículos publicados bajo la categoría ${tag}`,
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Articulos sobre {tag}</h2>
        <div>
          {articles.map(article => (
            <ArticleItem key={article.fields.slug} article={article} />
          ))}
        </div>
      </Main>
      <ArticlesSidebar tags={tags} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query TagPage($tag: [String]!) {
    articles: allMdx(
      filter: { frontmatter: { tags: { in: $tag } }, fields: { collection: { eq: "articles" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          title
          description
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 530, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    tags: allMdx(filter: { fields: { collection: { eq: "articles" } }, frontmatter: { tags: { nin: $tag } } }) {
      group(field: frontmatter___tags) {
        slug: fieldValue
        count: totalCount
      }
    }
  }
`
